import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

const ItemUserDisplay = (props) => {
  const defineLang = (lang) => {
    const languages = {
      arz: "Arabic-Egyption",
      qai: "Arabic-Iraqui",
      qav: "Arabic-Levantine",
      ara: "Arabic-MSA",
      cmn: "Chinese",
      hrv: "Croation",
      prs: "Dari",
      eng: "English",
      pes: "Farsi",
      fra: "French",
      // deu: "German",
      // ell: "Greek",
      // qag: "Gulf",
      heb: "Hebrew",
      kor: "Korean",
      nkr: "Korean/North Korean",
      pus: "Pashto",
      por: "Portuguese",
      rus: "Russian",
      srp: "Serbian",
      som: "Somali",
      spa: "Spanish",
      // apd: "Sudanese",
      tgl: "Tagalog",
      // tha: "Thai",
      tur: "Turkish",
      // tuk: "Turkmen",
      urd: "Urdu",
      // qay: "Yemeni",
    };
    return languages[lang] || lang.toUpperCase();
  };

  const [data, setData] = useState(props.content || []);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [clickedItems, setClickedItems] = useState({});

  useEffect(() => {
    setData(props.content || []);
  }, [props.content]);

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === "count") {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      } else if (key === "isPassed") {
        return a._id.isPassed === b._id.isPassed ? 0 : a._id.isPassed ? -1 : 1;
      } else {
        if (a._id[key] < b._id[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a._id[key] > b._id[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      "Testlet Item": item._id.testletItem,
      Frequency: item.count,
      "Pass/Fail": item._id.isPassed ? "Passed" : "Failed",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, "ItemUserDisplay.xlsx");
  };

  const lang = defineLang(props.lang);
  const mod = props.mod;
  const type = props.type;
  const itemLevel = props.itemLevel;
  const userLevel = mod === "grammar" ? "all" : props.userLevel;
  const fromDate = props.fromDate;
  const toDate = props.toDate;
  const click = props.click;
  const excludeAdmin = props.excludeAdmin;

  const handleDetailsClick = (testletItem, isPassed) => {
    const itemKey = `${testletItem}_${isPassed}`;
    setClickedItems((prev) => ({ ...prev, [itemKey]: true }));
    getDetails(testletItem, isPassed);
  }
  const getDetails = (testletItem, isPassed) => {
    const items = [testletItem, props.lang, mod, userLevel, isPassed, fromDate, toDate, excludeAdmin];
    console.log(items);
    // Store the selectedItems in localStorage
    localStorage.setItem("selectedItems", JSON.stringify(items));

    // Create the URL for the new page
    const url = "/adminreport/itemuser/detail";

    // Open a new tab with the URL
    window.open(url, "_blank");
  };

  const title_modality =
    lang + "  " + mod.charAt(0).toUpperCase() + mod.slice(1);
  const title_template =
    type === "all" || type === "All"
      ? type.charAt(0).toUpperCase() + type.slice(1) + " " + "Templates"
      : type.toUpperCase();

  const title_itemLvl = "Item Level " + itemLevel;

  const getBackgroundColor = (index) => {
    if (index === 0) return "#f0f0f0"; // Default color for the first item
    if (data[index]._id.testletItem !== data[index - 1]._id.testletItem) {
      return data[index - 1].backgroundColor === "#f0f0f0" ? "#d0d0d0" : "#f0f0f0";
    }
    return data[index - 1].backgroundColor;
  };
  
  // Update the background color for each item only if the data is not empty
  if (data.length > 0) {
    data.forEach((item, index) => {
      item.backgroundColor = getBackgroundColor(index);
    });
  }

  const baseStyle = {
    flex: 1,
    padding: "8px",
    textAlign: "left",
    borderTop: ".5px solid #c67a3aa",
    borderBottom: ".5px solid #67a3aa",
  };

  const getCellStyle = (
    backgroundColor,
    isPassed = true,
    clickable = false
  ) => ({
    ...baseStyle,
    backgroundColor,
    color: isPassed ? "inherit" : "#cb3e3e",
    cursor: clickable ? "pointer" : "default",
  });

  const headerStyle = {
    ...baseStyle,
    backgroundColor: "#187680",
    color: "#ffffff",
    letterSpacing: "1px",
  };

  const clickableHeaderStyle = {
    ...headerStyle,
    cursor: "pointer",
  };

  // Ensure Pass/Fail is sorted within each Testlet Item group
  const sortedData = data.sort((a, b) => {
    if (a._id.testletItem === b._id.testletItem) {
      return a._id.isPassed === b._id.isPassed ? 0 : a._id.isPassed ? -1 : 1;
    }
    return a._id.testletItem < b._id.testletItem ? -1 : 1;
  });

  return (
    <div>
      {click && sortedData.length > 0 && (
        <button
          style={{
            fontSize: "13px",
            borderRadius: "10px",
            height: "30px",
            paddingTop: "7px",
          }}
          onClick={exportToExcel}
        >
          Export to Excel
        </button>
      )}

      {click && sortedData.length == 0 ? (
        <div>No data available.</div>
      ) : (
        <>
          {sortedData.length > 0 && (
            <div style={{ fontSize: "18px", textAlign: "left" }}>
              <div>{title_modality}</div>
              <div>{title_template}</div>
              <div>{title_itemLvl}</div>
            </div>
          )}
          <br />
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {sortedData.length > 0 && (
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={clickableHeaderStyle}
                  onClick={() => sortData("testletItem")}
                >
                  Testlet Item
                </div>
                <div
                  style={clickableHeaderStyle}
                  onClick={() => sortData("count")}
                >
                  Frequency
                </div>
                <div
                  style={clickableHeaderStyle}
                  onClick={() => sortData("isPassed")}
                >
                  Pass/Fail
                </div>
                <div style={headerStyle}>Details</div>
              </div>
            )}
            {sortedData.map((item, index) => (
              <div style={{ display: "flex", width: "100%" }} key={index}>
                <div style={getCellStyle(item.backgroundColor)}>
                  {item._id.testletItem}
                </div>
                <div style={getCellStyle(item.backgroundColor)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.count}
                </div>
                <div
                  style={getCellStyle(item.backgroundColor, item._id.isPassed)}
                >
                  {item._id.isPassed ? "Passed" : "Failed"}
                </div>
                <div
                  style={{
                    ...getCellStyle(item.backgroundColor),
                    cursor: "pointer",
                    color: clickedItems[`${item._id.testletItem}_${item._id.isPassed}`]
                      ? "#551A8B"
                      : "inherit",
                  }}
                  onClick={() =>
                    handleDetailsClick(item._id.testletItem, item._id.isPassed)
                  }
                >
                  Details
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ItemUserDisplay;
